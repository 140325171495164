import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, of, shareReplay, take, throwError } from 'rxjs';
import { Logger } from 'src/app/@common/log/logger';
import { Video } from 'src/app/@domain/video/entities/video.interface';
import { environment } from 'src/environments/environment';
import { Page } from '../../../@common/entities/page.interface';
import { ApiPath } from '../../../@common/http/api-path.enum';
import { LectureViewInputDTO, V2VideoResource } from '../../../@generated';
import { UserService } from '../../user/application/user.service';
import { AuthorsLecturesFilters } from '../entities/author-lecture-filters.enum';
import { SortVideos } from '../entities/sort-videos.enum';
import UserTypeEnum = LectureViewInputDTO.UserTypeEnum;

@Injectable({
    providedIn: 'root',
})
export class VideoService {
    private http = inject(HttpClient);
    private userService = inject(UserService);
    private videoResource = inject(V2VideoResource);
    private logger = inject(Logger);
    private router = inject(Router);

    public getVideos(
        hits: string,
        language: string,
        limit: number,
        offset: number,
        sort: SortVideos,
        type: string,
        year: any
    ): Observable<Page<Video>> {
        return this.http.get<Page<Video>>(
            `${ ApiPath.lectures }/filter/?hits=${ hits }&language=${ language }&limit=${ limit }&offset=${ offset }&sort=${ sort }&type=${ type }&year=${ year }`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get lectures failed', e);
                return throwError(() => e);
            })
        );
    }

    public getLatestVideos(): Observable<Page<Video>> {
        return this.http.get(
            `${ ApiPath.lectures }/filter/?sort=published&limit=4&offset=0`,
            { withCredentials: true }
        )
            .pipe(
                shareReplay(1),
                catchError(e => {
                    this.logger.apiError('Get latest lectures failed', e);
                    return of({});
                })
            );
    }

    public getMostViewedVideos(): Observable<Page<Video>> {
        return this.http.get(
            `${ ApiPath.lectures }/filter/?sort=starred&limit=4&offset=0`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get most viewed lectures failed', e);
                return of({});
            })
        )
    }

    public getVideosForAuthor(id: number, sort: AuthorsLecturesFilters): Observable<Page<Video>> {
        return this.http.get<Page<Video>>(
            `${ ApiPath.authors }/${ id }/${ sort ?? AuthorsLecturesFilters.Views }/?limit=99999`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get lectures for author failed', e);
                return of({});
            })
        );
    }

    public getVideo(slug: string): Observable<Video> {
        return this.http.get<Video>(`${ ApiPath.lectures }/${ slug }/`, { withCredentials: true })
            .pipe(
                shareReplay(1),
                catchError(e => {
                    if (e.status === 403) {
                        this.router.navigate([ '/unauthorized' ]);
                    } else {
                        this.logger.apiError('Get lecture with slug failed', e);
                    }

                    return throwError(() => e);
                })
            )
    }

    getLeftOff(lectureId: number): Observable<number> {
        return this.http.get<number>(`${ environment.apiUrl }/left_off/${ lectureId }/`, { withCredentials: true })
            .pipe(
                shareReplay(1)
            );
    }

    public getUserUploadedVideos(limit: number, offset: number): Observable<any> {
        return this.http.get(
            `${ ApiPath.lectures }/user_lectures/?limit=${ limit }&offset=${ offset }`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get user uploaded lectures failed', e);
                return of({});
            })
        );
    }

    public addView(videoSlug: string) {
        this.videoResource.v2VideosViewsCreate(
            videoSlug,
            {
                user_id: this.userService.userProfile()?.user?.id?.toString() ?? this.userService.getAnonymousUserId(),
                user_type: this.userService.isAuthenticated() ? UserTypeEnum.Auth : UserTypeEnum.Unauth,
            }
        ).pipe(
            take(1),
            catchError(e => {
                this.logger.apiError(`Add view for videoSlug: ${ videoSlug } failed`, e);
                return EMPTY;
            })
        ).subscribe()
    }

}
