/**
 * Your Project API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@yourproject.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LectureViewInputDTO {
    user_id: string;
    user_type: LectureViewInputDTO.UserTypeEnum;
}

export namespace LectureViewInputDTO {
    export type UserTypeEnum = 'auth' | 'unauth';
    export const UserTypeEnum = {
        Auth: 'auth' as UserTypeEnum,
        Unauth: 'unauth' as UserTypeEnum
    };
}


